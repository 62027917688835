// generic debounce function to apply where necessary
// it accepts a function that will be debounced and a timeout for said debounce in milliseconds
export function debounce(func, ms) {
  let timeout
  return () => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      timeout = null
      func()
    }, ms)
  }
}
