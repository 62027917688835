
  import { debounce } from '@/utils/debounce'

  export default {
    name: 'CardsCarousel',
    props: {
      slice: {
        type: Object,
        required: true,
        default() {
          return {}
        },
      },
    },
    data() {
      return {
        windowWidth: 0,
        smBreakpoint: 640,
        lgBreakpoint: 1024,
        cardGroupPressed: 0,
      }
    },
    computed: {
      sliderIndicators() {
        let sliderCardCount = 1
        const buttons = []

        if (this.windowWidth >= this.smBreakpoint) {
          sliderCardCount = 2
        }

        if (this.windowWidth >= this.lgBreakpoint) {
          sliderCardCount = 3
        }

        for (let i = 0; i < this.slice.items.length; i++) {
          let itemNum = i + 1
          // if the item number is disivible by the current sliderCard count,
          // then return as a button index
          if (itemNum % sliderCardCount === 0)
            buttons.push({
              // if this is the first button, then always set the index to 1
              index: buttons.length,
              groupNum: buttons.length + 1 === 1 ? 1 : itemNum,
            })
          // if this is the last button,
          // then always set the index to i + 1,
          // even if its not divisible by the card count
          else if (
            itemNum === this.slice.items.length &&
            itemNum % sliderCardCount !== 0
          )
            buttons.push({
              index: buttons.length,
              groupNum: itemNum,
            })
        }

        return buttons
      },
      smoothScrollSupported() {
        return 'scrollBehavior' in document.documentElement.style
      },
    },
    methods: {
      debounce,
      updateWindowSize() {
        if (typeof window !== 'undefined') {
          this.windowWidth = window.innerWidth
        }
      },
      indicatorPressed(e, index) {
        e.stopPropagation()
        this.cardGroupPressed = index
        const scrollLeft = Math.floor(
          this.$refs.carousel.scrollWidth *
            (index / this.sliderIndicators.length)
        )
        this.smoothScroll(this.$refs.carousel, scrollLeft, true)
      },
      smoothScroll(node, topOrLeft, horizontal) {
        if (this.smoothScrollSupported) {
          return node.scrollTo({
            [horizontal ? 'left' : 'top']: topOrLeft,
            behavior: 'smooth',
          })
        } else {
          return this.smoothScrollPolyfill(
            node,
            horizontal ? 'scrollLeft' : 'scrollTop',
            topOrLeft
          )
        }
      },
      handleScroll() {
        let index = Math.round(
          (this.$refs.carousel.scrollLeft / this.$refs.carousel.scrollWidth) *
            this.sliderIndicators.length
        )
        this.cardGroupPressed = index
      },
      smoothScrollPolyfill(node, key, target) {
        const startTime = Date.now()
        const offset = node[key]
        const gap = target - offset
        const duration = 1000
        let interrupt = false
        const easingOutQuint = (x, t, b, c, d) =>
          c * ((t = t / d - 1) * t * t * t * t + 1) + b

        const step = () => {
          const elapsed = Date.now() - startTime
          const percentage = elapsed / duration

          if (interrupt) {
            return
          }

          if (percentage > 1) {
            cleanup()
            return
          }

          node[key] = easingOutQuint(0, elapsed, offset, gap, duration)
          requestAnimationFrame(step)
        }

        const cancel = () => {
          interrupt = true
          cleanup()
        }

        const cleanup = () => {
          node.removeEventListener('wheel', cancel)
          node.removeEventListener('touchstart', cancel)
        }

        node.addEventListener('wheel', cancel, { passive: true })
        node.addEventListener('touchstart', cancel, { passive: true })

        step()

        return cancel
      },
    },
    mounted() {
      this.updateWindowSize()
      if (typeof window !== 'undefined') {
        window.addEventListener('resize', this.updateWindowSize)
      }
      this.handleDebouncedScroll = debounce(this.handleScroll, 25)
      this.$refs.carousel.addEventListener('scroll', this.handleDebouncedScroll)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.updateWindowSize)
      this.$refs.carousel.removeEventListener(
        'scroll',
        this.handleDebouncedScroll
      )
    },
  }
